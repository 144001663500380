import React, { Suspense, lazy } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Buffer } from "buffer";
import Loading from "./components/Loading.js";

window.Buffer = window.Buffer || Buffer; // This is a workaround to allow some server-side node.js scripts to work in the browser (eg. azure-storage)

const HomePage = lazy(() => import("./pages/HomePage.js"));
const WaitingPage = lazy(() => import("./pages/WaitingPage.js"));
const ResultPage = lazy(() => import("./pages/ResultPage.js"));
const EmailFormPage = lazy(() => import("./pages/EmailFormPage.js"));
const CompletedPage = lazy(() => import("./pages/CompletedPage.js"));
const NotFound = lazy(() => import("./pages/NotFound.js"));

function App() {
  const location = useLocation();

  return (
    <div className="App">
      <Suspense fallback={<Loading />}>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<HomePage />} />
          <Route path="/waiting" element={<WaitingPage />} />
          <Route path="/result" element={<ResultPage />} />
          <Route path="/emailform" element={<EmailFormPage />} />
          <Route path="/completed" element={<CompletedPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
